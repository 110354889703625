.packages_main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.carSelect {
  padding: 5px;
  border: none;
  background-color: white;
  border-radius: 5px;
  margin: 20px auto;
  width: 150px;
}
.carOption {
  padding: 15px;
  border: none;
  background-color: white;
  border-radius: 5px;
}

.packages_container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: 40%;
}

.packages_add_container {
  display: flex;
  width: 100%;

  align-items: center;
  height: 60%;
  flex-direction: column;
}

.package_card {
  border: none;
  width: 180px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  position: relative;
}

.packages-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

.flex_btns {
  display: flex;
  width: 50%;
  justify-content: space-around;
}
