.booking_card {
  width: 100%;
  padding: 10px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 1px 1px 1px 1px;
  margin-top: 15px;
  position: relative;
  min-height: 400px;
}
.flexible_head {
  display: flex;
  justify-content: space-around;
  height: 30px;
}
.services_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid gray;
  padding: 5px;
  flex-direction: row;
}

.detail_container {
  display: flex;
  width: 100%;
  border-top: 1px solid gray;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
/* .detail_name {
} */
.detail {
  width: 60%;
  font-weight: 700;
  word-wrap: break-word;
  flex-wrap: wrap;
}

.booking_nav_container {
  width: 100%;
  height: 50px;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.booking_links {
  font-size: 14px;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  background-color: gray;
  padding: 10px;
  border-radius: 8px;
}

.active_booking_links {
  font-size: 14px;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  background-color: #194f92;
  padding: 10px;
  border-radius: 8px;
  text-decoration: underline;
}

.bookings_buttons_container {
  display: flex;
  justify-content: space-around;
  margin: 5px;
}

.bookings_button_start,
.bookings_button_done,
.bookings_button_cancel {
  font-size: 14px;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
}
.bookings_button_start {
  background-color: green;
}
.bookings_button_done {
  background-color: gray;
}
.bookings_button_cancel {
  background-color: red;
}

.edit_time {
  border: none;
  font-size: 14px;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  padding: 2px 6px;
  border-radius: 7px;
  position: absolute;
  right: 20px;
  background-color: lightgray;
  cursor: pointer;
}

.cancel {
  border: none;
  font-size: 14px;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  padding: 2px 6px;
  border-radius: 7px;
  position: absolute;
  right: 140px;
  background-color: lightgray;
  cursor: pointer;
}
