.contact_main_container {
  display: flex;
  width: 100%;
  height: 100%;
}

.contact_left_container {
  width: 60%;
  height: 100%;
}
.contact_right_container {
  width: 40%;
  height: 100%;
}

.contact_container_header {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}

.rows {
  display: flex;
  justify-content: space-between;
  width: 80%;
  min-height: 60px;
  background-color: white;
  margin: 35px auto;
  padding: 0px 10px;
  align-items: center;
  border-radius: 8px;
}

.contact_title {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}

.brandLogo {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.contact-inp-cont {
  width: 100%;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-inp-cont-logo {
  border-top: 1px solid gray;
  width: 100%;
  min-height: 160px;
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
