.nav-links {
  margin: 10px !important;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  background-color: white;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 7px;
  border-radius: 8px;
}

.active-nav-links {
  margin: 10px !important;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-decoration: underline;
  background-color: #f2f1ef;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 7px;
  border-radius: 8px 40px 40px 8px;
}

.sideBar {
  background-color: transparent;
}