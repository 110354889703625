.header-container {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  box-shadow: 1px 1px 1px 1px #f2f1ef;
}

.logout-btn {
  width: 200px;
  height: 50px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-weight: 700;
  background-color: #697184;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
