.App {
  text-align: center;
}

.loyout-container {
  /* background-image: linear-gradient(#d8cfd0, #b1a6a4); */
  background-image: url('../src/Img/background.jpg');
  background-position: center;
}

.container {
  display: flex;
  width: 100%;
}

.sideBar {
  width: 280px;
  padding: 20px;
  background-color: transparent;
}

.page_container {
  width: 100%;
  min-height: 680px;
  max-height: 680px;
  padding: 20px;
  overflow-y: auto;
  background-color: #6474e5;
}

::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.isNotData {
  font-size: 18px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  margin: 30px auto;
  text-align: center;
}
