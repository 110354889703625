.extra_services_container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 680px;
  background-color: #6474e5;
  overflow: auto;
}

.extra_card {
  width: 90%;
  padding: 5px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin: 15px auto;
  align-items: center;
  position: relative;
  height: 50px;
}

.extra_services_left_ontainer {
  display: flex;
  flex-direction: column;
  width: 60%;

  overflow-y: auto;
}

.extra_services_right_ontainer {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.flexible {
  display: flex;
  width: 100%;
  height: 85%;
}