.bookingtime_container {
  width: 100%;
  min-height: 680px;
  max-height: 680px;
  background-color: transparent;
  padding: 20px;
  display: flex;
  background-color: #6474e5;
}

.left_bookingtime_container,
.right_bookingtime_container {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0px auto;
  overflow-y: auto;
}

.date_card {
  width: 90%;
  padding: 5px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin: 8px auto;
  align-items: center;
}

.date_deletion {
  border: none;
  background-color: lightgray;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  padding: 6px;
  border-radius: 8px;
}

.date_deletion:hover {
  cursor: pointer;
  background-color: gray;
}

.open_dates {
  width: 100%;
  height: 75%;
  overflow-y: scroll;
  margin: 20px auto;
}

.booked_dates {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  margin: 20px auto;
}

.dates_header_text {
  margin-top: 15px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}

.add_date {
  border: none;
  background-color: lightgray;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  padding: 6px;
  border-radius: 8px;
  margin: 10px;
}

.add_date:hover {
  cursor: pointer;
  background-color: gray;
}