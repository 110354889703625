.News_card {
  width: 250px;
  height: 350px;
  margin: 20px 0px;
  position: relative;
  background-color: white;
  border-radius: 15px;
}

.news_left_container {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.existing_details {
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
}

.news_right_container {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news_container_header {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}

.news_img_container {
  width: 100%;
  height: 170px;
  border-radius: 15px;
}
.news_body_container {
  width: 100%;
  border-radius: 15px;
  height: 180px;
}

.news_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 15px;
}

.news_date,
.news_title,
.news_description {
  font-size: 16px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  padding: 5px;
}

.news_description {
  height: 100px;
  overflow: hidden;
}

.delete_btn {
  position: absolute;
  bottom: -15px;
  right: -15px;
}

.delete_btn:hover {
  cursor: pointer;
}

.edit_btn:hover {
  cursor: pointer;
}

.edit_btn {
  position: absolute;
  bottom: -15px;
  left: -10px;
  width: 28px;
  height: 28px;
}
