.login-container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.login-form {
  margin-top: 50px;
  width: 90%;
  height: 600px;
  background-image: url('../../Img/background.jpg');
  background-position: center;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.inp-cont {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.label {
  font-size: 14px;
  text-align: center;
  padding-bottom: 15px;
  white-space: nowrap;
  color: white;
}

input[type='text'],
input[type='password'],
input[type='tel'] {
  border: none;
  padding: 5px;
  border-radius: 5px;
  width: 200px;
  align-self: center;
}

.recovery-link {
  text-decoration: underline;
  font-size: 16px;
  color: white;
}

.submit-btn {
  border: none;
  border-radius: 5px;
  background-color: white;
  color: black;
  padding: 5px 8px;
  cursor: pointer;
  margin-bottom: 20px;
  height: 30px;
}

.login_header_text {
  font-size: 16px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}

.cancel-btn {
  border: none;
  border-radius: 5px;
  background-color: white;
  color: black;
  padding: 5px 8px;
  cursor: pointer;
  margin-left: 10px;
  height: 30px;
}
