.service_edit {
  width: 100%;
  height: 450px;
}

.edit_main {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: lightgray;
  margin-top: 8px;
  border-radius: 10px;
}

.left_edit,
.right_edit {
  width: 45%;
  height: 380px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding: 5px;
}

.cancel_btn {
  border: none;
  border-radius: 10px;
  background-color: gray;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin: 15px 0px 0px 15px;
  padding: 5px;
  cursor: pointer;
}

.card_btn {
  border: none;
  border-radius: 10px;
  background-color: gray;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  padding: 5px;
  cursor: pointer;
}

.card_btn_done {
  border: none;
  border-radius: 10px;
  background-color: green;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  padding: 5px;
  cursor: pointer;
}

.card_btn,
.cancel_btn:hover {
  opacity: 0.7;
}

.editPrice {
  position: absolute;
  bottom: 40px;
  left: 30px;
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  width: 60px;
  text-align: center;
  padding: 8px;
  border-radius: 10px;
}